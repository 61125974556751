import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Box, Button, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import React from 'react';
import { useAppSelector } from '../../../app/store';
import { formatDayjsDateWithoutTime } from '../../../common/utils/dateUtil';
import orderLookupApi from '../orderLookupApi';
import { Shipment, Sscc } from '../orderLookupModels';
import { QcLogDialog } from './QcLog/QcLogDialog';
import { SsccDialog } from './SsccDialog';

// Adds id attribute to Shipment objects to work with DataGrid
interface GridShipment extends Shipment {
    id: number; // DataGrid needs id attribute for each element.
}

export function ShipmentsDisplay() {
    const momCode = useAppSelector(state => state.orderLookup.momCode);
    const orderId = useAppSelector(state => state.orderLookup.orderId);

    const getOrderDetailsResponse = orderLookupApi.endpoints.getOrderDetails.useQuery(
        momCode && !!orderId ? { momCode, orderId } : skipToken
    );
    const shipmentsData = getOrderDetailsResponse.data?.Data[0]?.Shipments;
    const itemsData = getOrderDetailsResponse.data?.Data[0]?.Items;
    const qcLogData = getOrderDetailsResponse.data?.Data[0]?.QcLogs;

    const gridShipmentsData: GridShipment[] = [];

    shipmentsData?.map((shipment, index) => {
        gridShipmentsData.push({ ...shipment, id: index + 1 });
    });

    // SSCC
    // Dialog box for shipment SSCC
    // Disables the buttons if no SSCC available for the Inpart
    const ssccButtonDisabled = (inpartId: string) => {
        itemsData?.forEach(item => {
            if (item.Inpart === inpartId && item.Ssccs.length > 0) {
                return false;
            }
        });
        return true;
    };

    const [ssccDialogOpen, setSsccDialogOpen] = React.useState(false);
    const [selectedInpartSscc, setSelectedInpartSscc] = React.useState('');
    const [sscc, setSscc] = React.useState<Sscc[]>([]);

    // Opens the dialog box and collects SSCCs list for it to render.
    React.useEffect(() => {
        let collectedSscc = new Set<Sscc>();
        if (!!selectedInpartSscc) {
            // Looks for correct Inpart id within items object
            itemsData?.forEach(item => {
                if (item.Inpart === selectedInpartSscc && item.Ssccs.length > 0) {
                    item.Ssccs.forEach(sscc => collectedSscc.add(sscc));
                    setSscc([...collectedSscc]);
                }
            });
            setSsccDialogOpen(true);
        }
    }, [selectedInpartSscc, ssccDialogOpen]);

    React.useEffect(() => {
        if (ssccDialogOpen) {
            setSsccDialogOpen(false); // Allows for reopening of dialog box.
            setSelectedInpartSscc(''); // Allow for reopening of dialog box with same info.
        }
    }, [ssccDialogOpen]);

    // QC LOG
    // Disables the buttons if no QC Log available for the Inpart
    const qcLogButtonDisabled = (inpartId: string) => {
        qcLogData?.forEach(qcLog => {
            let inpart = qcLog.Order.slice(-1);
            if (inpart === inpartId) {
                return false;
            }
        });
        return true;
    };

    const [qcLogDialogOpen, setQcLogDialogOpen] = React.useState(false);
    const [selectedQcLogInvoice, setSelectedQcLogInvoice] = React.useState('');

    const handleSetQcLogDialogData = (inpartId: string) => {
        const selectedQcLog = qcLogData?.filter(qcLog => qcLog.Order.slice(-1) === inpartId);
        if (!!selectedQcLog) {
            setSelectedQcLogInvoice(selectedQcLog[0].Order);
            setQcLogDialogOpen(true);
        }
    };

    const columns: GridColDef[] = [
        {
            align: 'center',
            headerAlign: 'center',
            field: 'Inpart',
            headerName: 'Inpart',
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'ShipMethod',
            headerName: 'Ship Method',
            flex: 2,
        },
        {
            field: 'TrackingNumber',
            headerName: 'Tracking #',
            flex: 1.4,
        },
        {
            align: 'left',
            headerAlign: 'left',
            field: 'ShipDate',
            headerName: 'Ship Date',
            type: 'datetime',
            valueFormatter: params => formatDayjsDateWithoutTime(params.value),
            flex: 1,
        },
        {
            align: 'center',
            headerAlign: 'center',
            field: 'NetWeight',
            headerName: 'Net Weight',
            type: 'number',
            flex: 1,
        },
        {
            field: 'sscc',
            headerName: 'SSCC',
            align: 'right',
            headerAlign: 'center',
            renderCell: params => (
                <Tooltip title={ssccButtonDisabled(params.row.Inpart) ? 'No SSCC' : ''} placement="top-start">
                    <span>
                        {/* Added span to have Tooltip work for disabled Button */}
                        <Button
                            disabled={ssccButtonDisabled(params.row.Inpart)}
                            variant="text"
                            onClick={() => {
                                setSelectedInpartSscc(params.row.Inpart);
                            }}
                        >
                            <LayersRoundedIcon color={ssccButtonDisabled(params.row.Inpart) ? 'disabled' : 'primary'} />
                        </Button>
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'qcLog',
            headerName: 'QC LOG',
            align: 'right',
            headerAlign: 'center',
            renderCell: params => (
                <Tooltip title={qcLogButtonDisabled(params.row.Inpart) ? 'No QC Logs' : ''} placement="top-start">
                    <span>
                        {/* Added span to have Tooltip work for disabled Button */}
                        <Button
                            disabled={qcLogButtonDisabled(params.row.Inpart)}
                            variant="text"
                            onClick={() => {
                                handleSetQcLogDialogData(params.row.Inpart);
                            }}
                        >
                            <PlaylistAddCheckIcon
                                color={qcLogButtonDisabled(params.row.Inpart) ? 'disabled' : 'primary'}
                            />
                        </Button>
                    </span>
                </Tooltip>
            ),
        },
    ];

    if (!!shipmentsData) {
        return (
            <Box style={{ width: 1120 }}>
                <SsccDialog sscc={sscc} showDialog={ssccDialogOpen} inpartId={selectedInpartSscc} />
                <QcLogDialog
                    invoice={selectedQcLogInvoice}
                    setQcLogDialogOpen={setQcLogDialogOpen}
                    showDialog={qcLogDialogOpen}
                />
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'Inpart', sort: 'asc' }],
                        },
                    }}
                    autoHeight
                    rows={gridShipmentsData || []}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooter
                />
            </Box>
        );
    } else {
        return (
            <Box>
                <Typography> NO SHIPMENTS </Typography>
            </Box>
        );
    }
}
