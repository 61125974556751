import { Grid, Paper, Skeleton } from '@mui/material';

const LoadingCards = () => {
    return (
        <Grid container sx={{ mt: 4, display: 'flex' }} spacing={3}>
            {Array.from({ length: 4 }, (_, ind) => (
                <Grid item xs={12} sm={6} md={4} key={ind} lg={3}>
                    <Paper>
                        <Skeleton variant="rectangular" animation="wave" width={290} height={148} />
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            width={290}
                            height={30}
                            style={{ margin: '0.5rem 0' }}
                        />
                        <Skeleton variant="rectangular" animation="wave" width={290} height={30} />
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default LoadingCards;
