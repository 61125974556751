import { createApi } from '@reduxjs/toolkit/query/react';
import { GET_COMPANIES_URL, GET_USERS_URL } from '../../app/services/api_constants';
import { roapiBaseQueryWithReauth } from '../../app/services/roapiBaseQueryWithReauth';
import { Company, User } from './componentsModel';

export default createApi({
    reducerPath: 'componentsApi',
    baseQuery: roapiBaseQueryWithReauth,
    endpoints: builder => ({
        getCompanies: builder.query<Company[], boolean>({
            query: params => ({
                url: `${GET_COMPANIES_URL}`,
                method: 'GET',
                params: { source: 'DreamReport', isActive: params },
            }),
        }),
        getUsers: builder.query<User[], void>({
            query: () => ({
                url: `${GET_USERS_URL}`,
                method: 'GET',
            }),
        }),
    }),
});
