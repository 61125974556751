import { Box } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files-ro';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { AttachmentFile } from '../../workOrderModels';
import { overrideForm } from '../../workOrderSlice';

const toBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    });
};

const getContent = (base64: string) => {
    return base64?.split(',')[1];
};

const getEncodingHeaeder = (base64: string) => {
    return base64?.split(',')[0] + ',';
};

const FILE_UPLOAD_MESSAGE = `Browse for or drag/drop file to attach`;

const AttachmentFileForm = ({ isDisabled }: { isDisabled: boolean }) => {
    const attachmentFiles = useAppSelector(state => state.workOrder.form.AttachmentFiles);

    const dispatch = useAppDispatch();

    const handleChange = async (files: FileList) => {
        const filesArray = Array.from(files);

        const convertedFiles = await Promise.all(
            filesArray.map(async file => {
                const base64Encoded = await toBase64(file);
                return {
                    Name: file.name,
                    Content: getContent(base64Encoded),
                    EncodingHeader: getEncodingHeaeder(base64Encoded),
                } as AttachmentFile;
            })
        );

        dispatch(overrideForm({ AttachmentFiles: [...attachmentFiles, ...convertedFiles] }));
    };

    return (
        <Box sx={{ my: 2, display: isDisabled ? 'none' : '' }}>
            <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                label={FILE_UPLOAD_MESSAGE}
                uploadedLabel={FILE_UPLOAD_MESSAGE}
            />
        </Box>
    );
};

export default AttachmentFileForm;
