import { Box, styled } from '@mui/material';
import {
    DataGrid,
    GridEventListener,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../../app/store';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import { SummarizedWorkOrderData } from '../../workOrderModels';

interface WorkOrderDataGridProps {
    data: SummarizedWorkOrderData[];
}
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
    },
}));

const WorkOrderDataGrid = ({ data }: WorkOrderDataGridProps) => {
    const tab = useAppSelector(state => state.workOrder.tab);

    const workOrderColumns = [
        {
            field: 'MomCode',
            headerName: 'Client',
            width: 70,
        },
        {
            field: 'Id',
            headerName: 'Work Order',
            width: 100,
        },
        {
            field: 'ProjectTitle',
            headerName: 'Project Title',
            width: 300,
        },
        {
            field: 'RoRequester',
            headerName: 'Requested By',
            width: 150,
        },
        {
            field: 'AssignedTo',
            headerName: 'Assigned To',
            width: 150,
        },
        {
            field: 'DateCreated',
            headerName: 'Created',
            width: 100,
            valueGetter: (params: any) => formatDateWithoutTime(params.row.DateCreated),
        },
        {
            field: 'DueDate',
            headerName: 'Due',
            width: 100,
            valueGetter: (params: any) => formatDateWithoutTime(params.row.DueDate),
        },
        {
            field: 'DateCompleted',
            headerName: 'Completed',
            width: 110,
            valueGetter: (params: any) =>
                params.row.DateCompleted ? formatDateWithoutTime(params.row.DateCompleted) : '',
        },
    ];

    const templateColumns = [
        {
            field: 'MomCode',
            headerName: 'Client',
            width: 70,
        },
        {
            field: 'Id',
            headerName: 'Work Order',
            width: 100,
        },
        {
            field: 'ProjectTitle',
            headerName: 'Project Title',
            width: 300,
        },
        {
            field: 'RoRequester',
            headerName: 'Requested By',
            width: 150,
        },
        {
            field: 'AssignedTo',
            headerName: 'Assigned To',
            width: 150,
        },
        {
            field: 'DateCreated',
            headerName: 'Created',
            width: 100,
            valueGetter: (params: any) => formatDateWithoutTime(params.row.DateCreated),
        },
    ];

    const [searchParams] = useSearchParams();

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        window.open(`/workOrder/${params.row.Id}?${searchParams}`);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <Box>
            <StyledDataGrid
                rows={data}
                columns={tab === 'Work Orders' ? workOrderColumns : templateColumns}
                getRowId={row => row.Id}
                columnBuffer={8}
                sx={{ mt: 2 }}
                onRowClick={handleRowClick}
                slots={{ toolbar: CustomToolbar }}
                density="compact"
            />
        </Box>
    );
};

export default WorkOrderDataGrid;
