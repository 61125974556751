import { Box, Button, TextField } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import componentsApi from '../../../common/components/componentsApi';
import { isFeatureAccessible } from '../../../common/utils/commonUtils';
import { INACTIVE_COMPANY_VIEW } from '../../roles/permissionsList';
import authenticationApi from '../../authentication/authenticationApi';

export function OrderLookupForm() {
    const routeParams: any = useParams();
    const orderId = !!routeParams?.orderId ? routeParams?.orderId : '';
    const momCode = !!routeParams?.momCode ? routeParams?.momCode?.toUpperCase() : '';
    const [enteredOrder, setEnteredOrder] = React.useState(`${momCode}${orderId}`);
    const getUserPermissions = authenticationApi.endpoints.getUserPermissions.useQuery();
    const userPermissions: string[] = getUserPermissions.data || [];
    const shouldGetOnlyActiveClients = !isFeatureAccessible(userPermissions, INACTIVE_COMPANY_VIEW);
    const validCompaniesReponses = componentsApi.endpoints.getCompanies.useQuery(shouldGetOnlyActiveClients);
    const defaultHelperTextString = 'Example: "WPI1234" (Company + Order Id)';
    const [helperText, setHelperText] = React.useState(defaultHelperTextString);
    const [hasTextFieldError, setHasTextFieldError] = React.useState(false);
    const navigate = useNavigate();

    // Input parser
    const parseEnteredOrder = (enteredOrder: string) => {
        // Captures the first group of chars as non-digits, second group as digits, and an optional last non-digit char.
        const orderStringRegex = /^([\D\d]*?)(\d+)([^\d])?$/;
        const matches = enteredOrder.match(orderStringRegex);

        if (matches) {
            const [, momCode, orderId, inpart] = matches;
            return [momCode, orderId, inpart || ''];
        } else {
            return null;
        }
    };

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                setHelperText(defaultHelperTextString);
                setHasTextFieldError(false);
                const [parsedMomCode, parsedOrderId, parsedInPart] = parseEnteredOrder(enteredOrder) || [];
                if (parsedMomCode && !!parsedOrderId) {
                    const isValidMomCode = validCompaniesReponses?.data?.find(
                        company => company.mom === parsedMomCode.toUpperCase()
                    );
                    if (isValidMomCode) {
                        navigate(`/orderLookup/${parsedMomCode.toUpperCase()}/${parseInt(parsedOrderId)}`);
                    } else {
                        setHelperText(`"${parsedMomCode.toUpperCase()}" is not a valid client code.`);
                        setHasTextFieldError(true);
                    }
                } else {
                    setHasTextFieldError(true);
                }
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <TextField
                    error={hasTextFieldError}
                    sx={{ flex: 4 }}
                    value={enteredOrder}
                    label="Enter Order"
                    helperText={helperText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        let trimmedValue = event.target.value.replace(/\s+/g, '');
                        setEnteredOrder(trimmedValue);
                    }}
                />
                <Button type="submit" sx={{ flex: 1, mb: 3, ml: 1 }} variant="contained">
                    Look Up
                </Button>
            </Box>
        </form>
    );
}
