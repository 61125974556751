import styled from '@emotion/styled';
import { Dialog, DialogTitle } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridValidRowModel,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { CompletedWorkOrderDetail, EndOfMonthServiceRecord, ServiceRecord } from '../../workOrderModels';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:hover': {
        cursor: 'pointer',
    },
}));

const getTotalDuration = (currServices: EndOfMonthServiceRecord[], serviceCode: string) => {
    const matchingServices = currServices.filter(service => service.Code === serviceCode);
    const totalDuration = matchingServices.reduce((accumulator, service) => {
        return accumulator + service.Duration;
    }, 0);
    return totalDuration.toFixed(2);
};

const ServiceDataGrid = ({
    data,
    handleClose,
    services,
    clientName,
}: {
    data: CompletedWorkOrderDetail[];
    handleClose: () => void;
    services: ServiceRecord[];
    clientName: string;
}) => {
    const columns: readonly GridColDef<GridValidRowModel>[] = [
        {
            field: 'WorkOrderId',
            headerName: 'Id',
            width: 70,
        },
        {
            field: 'AssignedTo',
            headerName: 'Assigned To',
            width: 150,
        },
        ...services.map(
            (service): GridColDef<GridValidRowModel> => {
                return {
                    field: service.Code,
                    headerName: service.Code,
                    width: 80,
                    description: service.Description,
                    sortable: false,
                    valueGetter: params => {
                        return getTotalDuration(params.row.Services, service.Code);
                    },
                };
            }
        ),
    ];

    const navigate = useNavigate();

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        navigate(`/workOrder/${params.row.WorkOrderId}`);
    };

    return (
        <Dialog onClose={handleClose} open={true} maxWidth={'xl'}>
            <DialogTitle>Completed Work Orders for {clientName}</DialogTitle>
            <StyledDataGrid
                rows={data || []}
                columns={columns}
                getRowId={row => row.WorkOrderId}
                columnBuffer={20}
                sx={{ m: 3 }}
                density="compact"
                onRowClick={handleRowClick}
                slots={{ toolbar: CustomToolbar }}
            />
        </Dialog>
    );
};

export default ServiceDataGrid;
