import AddIcon from '@mui/icons-material/Add';
import { Box, Tab, Tabs } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { CreatePlusIcon } from '../../../common/components/button/CreatePlusIcon';
import { UNASSIGNED_TEMPLATE_TYPE_ID } from '../constants';
import { setToTemplatesTab, setToWorkOrderTab } from '../util';
import { WorkOrderData } from '../workOrderModels';
import { setForm, setMode, setTab } from '../workOrderSlice';
import EndOfMonthReport from './EndOfMonthReport/EndOfMonthReport';
import WorkOrderCreateDialog from './Form/WorkOrderCreateDialog';
import ViewWorkOrders from './View/ViewWorkOrders';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const initialWorkOrderForm = {
    Id: null,
    DateCreated: dayjs().format(),
    DateCompleted: null,
    Completed: false,
    CompletedBy: null,
    MomCode: null,
    AssignedTo: '',
    ProjectTitle: '',
    DueDate: dayjs().add(1, 'week').format(),
    Instructions: '',
    User: null,
    ReceivedBy: null,
    RoRequester: null,
    ClientContact: null,
    ChargeCode: '',
    Printed: false,
    PrintDate: null,
    Template: false,
    MesgId: null,
    TemplateType: 'personal',
    TemplateOwner: null,
    TemplateUpdated: dayjs().format(),
    Deleted: false,
    DeletedDate: null,
    DeletedBy: null,
    Viewed: false,
    ViewedDate: null,
    AttachmentFiles: [],
    AttachmentItems: [],
    BillingItems: [],
    BillsOfMaterialsItems: [],
    BillOfMaterialsComment: '',
    EditLogItems: [],
    TemplateTypeId: UNASSIGNED_TEMPLATE_TYPE_ID,
    WorkOrderProcedures: [],
} as WorkOrderData;

//need to map to numbers so tab highlights correctly on click
const tabMap: { [key: string]: number } = {
    'Work Orders': 0,
    Templates: 1,
    'End Of Month': 2,
};

const WorkOrderElement = () => {
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const tab = useAppSelector(state => state.workOrder.tab);

    const dispatch = useAppDispatch();

    const fullName = useAppSelector(state => state.authentication.fullName);

    const fixedInitialForm = {
        ...initialWorkOrderForm,
        RoRequester: fullName,
        TemplateOwner: fullName,
    } as WorkOrderData;

    const handleCreatePlusIconClick = () => {
        if (tab === 'Work Orders') dispatch(setForm({ ...fixedInitialForm }));
        else if (tab === 'Templates') {
            dispatch(
                setForm({
                    ...fixedInitialForm,
                    TemplateTypeId: 2,
                })
            );
        }

        dispatch(setMode('Create'));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const handleTabChange = (e: React.SyntheticEvent<Element, Event>, newTabNumber: number) => {
        if (newTabNumber === 0) {
            setToWorkOrderTab(dispatch);
        } else if (newTabNumber === 1) {
            setToTemplatesTab(dispatch);
        } else if (newTabNumber === 2) {
            dispatch(setTab('End Of Month'));
        }
        setSearchParams();
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabMap[tab]} onChange={handleTabChange}>
                    <Tab label="Work Orders" {...a11yProps(0)} />
                    <Tab label="Templates" {...a11yProps(1)} />
                    <Tab label="End of Month" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <Box sx={{ p: 0 }}>
                {tab === 'Work Orders' && (
                    <>
                        <WorkOrderCreateDialog isCreateDialogOpen={mode === 'Create'} />
                        <ViewWorkOrders />
                        <CreatePlusIcon
                            testId="create-workOrder-button"
                            label="add"
                            buttonIcon={AddIcon}
                            showDialog={handleCreatePlusIconClick}
                        />
                    </>
                )}
                {tab === 'Templates' && (
                    <>
                        <WorkOrderCreateDialog isCreateDialogOpen={mode === 'Create'} />
                        <ViewWorkOrders />
                        <CreatePlusIcon
                            testId="create-workOrder-button"
                            label="add"
                            buttonIcon={AddIcon}
                            showDialog={handleCreatePlusIconClick}
                        />
                    </>
                )}
                {tab === 'End Of Month' && <EndOfMonthReport />}
            </Box>
        </Box>
    );
};

export default WorkOrderElement;
