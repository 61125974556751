// import { offline } from 'redux-offline';
// import offlineConfig from 'redux-offline/lib/defaults';
// import TrackingReducer from '../features/tracking/TrackingReducer';
import { combineReducers } from 'redux';
import rolesReducer from '../archive/reducers/roleReducer';
import usersReducer from '../archive/reducers/usersReducer';
import componentsApi from '../common/components/componentsApi';
import addSerialApi from '../features/addSerial/addSerialApi';
import authenticationSlice from '../features/authentication/authenticationSlice';
import oldReceiversApi from '../features/inbound/oldReceiversApi';
import oldReceiversSlice from '../features/inbound/oldReceiversSlice';
import notificationSlice from '../features/notification/notificationSlice';
import orderLookupApi from '../features/orderLookup/orderLookupApi';
import orderLookupSlice from '../features/orderLookup/orderLookupSlice';
import qualityControlApi from '../features/qualityControl/qualityControlApi';
import qualityControlSlice from '../features/qualityControl/qualityControlSlice';
import rapidQcApi from '../features/rapidQc/rapidQcApi';
import rapidQcSlice from '../features/rapidQc/rapidQcSlice';
import receiversSlice from '../features/receivers/receiversSlice';
import rmaApi from '../features/rma/rmaApi';
import rmaSlice from '../features/rma/rmaSlice';
import serialLocationApi from '../features/serialLocation/serialLocationApi';
import shipMatrixApi from '../features/shipMatrix/shipMatrixApi';
import trackingApi from '../features/tracking/trackingApi';
import trackingSlice from '../features/tracking/trackingSlice';
import versionApi from '../features/version/versionApi';
import workOrderSlice from '../features/workOrder/workOrderSlice';
import { baseApi } from './services/baseApi';

export const reducers = combineReducers({
    authentication: authenticationSlice,
    qualityControl: qualityControlSlice,
    workOrder: workOrderSlice,
    notification: notificationSlice,
    receivers: receiversSlice,
    oldReceivers: oldReceiversSlice,
    tracking: trackingSlice,
    rma: rmaSlice,
    users: usersReducer,
    roles: rolesReducer,
    [oldReceiversApi.reducerPath]: oldReceiversApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [qualityControlApi.reducerPath]: qualityControlApi.reducer,
    [trackingApi.reducerPath]: trackingApi.reducer,
    [versionApi.reducerPath]: versionApi.reducer,
    [componentsApi.reducerPath]: componentsApi.reducer,
    [rmaApi.reducerPath]: rmaApi.reducer,
    [addSerialApi.reducerPath]: addSerialApi.reducer,
    [shipMatrixApi.reducerPath]: shipMatrixApi.reducer,
    [serialLocationApi.reducerPath]: serialLocationApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [rapidQcApi.reducerPath]: rapidQcApi.reducer,
    orderLookup: orderLookupSlice,
    rapidQc: rapidQcSlice,
});

export const slicesToNotPersist = [
    'notification',
    'receivers',
    'workOrder',
    baseApi.reducerPath,
    qualityControlApi.reducerPath,
    trackingApi.reducerPath,
    versionApi.reducerPath,
    componentsApi.reducerPath,
    rmaApi.reducerPath,
    addSerialApi.reducerPath,
    shipMatrixApi.reducerPath,
    serialLocationApi.reducerPath,
];

export const concatMiddleware = (input: any[]): any[] =>
    input
        .concat(trackingApi.middleware)
        .concat(baseApi.middleware)
        .concat(serialLocationApi.middleware)
        .concat(oldReceiversApi.middleware)
        .concat(versionApi.middleware)
        .concat(componentsApi.middleware)
        .concat(qualityControlApi.middleware)
        .concat(rmaApi.middleware)
        .concat(addSerialApi.middleware)
        .concat(shipMatrixApi.middleware)
        .concat(orderLookupApi.middleware)
        .concat(rapidQcApi.middleware);
