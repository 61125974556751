import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { blueGrey, deepPurple, green, lime, purple, red } from '@mui/material/colors';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import rmaApi from '../rmaApi';
import { TransformedGetRmaItem, TransformedGetRmaItemSerialVerify, TransformedGetRmaItemsResponse } from '../rmaModels';
import { deleteFromAllSerials, setToGetSerialsFor } from '../rmaSlice';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .incomplete': {
        backgroundColor: red['A100'],
        '&:hover': { backgroundColor: red['A100'] },
        '&.Mui-selected': {
            backgroundColor: red['A200'],
            '&:hover': {
                backgroundColor: red['A200'],
            },
        },
    },

    '& .inProgress': {
        backgroundColor: blueGrey['A100'],
        '&:hover': { backgroundColor: blueGrey['A100'] },
        '&.Mui-selected': {
            backgroundColor: blueGrey['A200'],
            '&:hover': {
                backgroundColor: blueGrey['A200'],
            },
        },
    },

    '& .accepted': {
        backgroundColor: green['A200'],
        '&:hover': { backgroundColor: green['A200'] },
        '&.Mui-selected': {
            backgroundColor: green['A400'],
            '&:hover': {
                backgroundColor: green['A400'],
            },
        },
    },
}));

const renderBooleanCell = (value: boolean) => {
    return value ? (
        <CheckIcon
            style={{
                color: green[500],
            }}
            data-testid="check-icon"
        />
    ) : (
        <CloseIcon
            style={{
                color: red[500],
            }}
            data-testid="close-icon"
        />
    );
};

const RmaDataGrid = () => {
    const dispatch = useAppDispatch();
    const rmaState = useAppSelector(state => state.rma);
    const getRmaItemsResponse =
        (rmaApi.endpoints.getRmaItems.useQuery(
            rmaState.rmaNumber ? { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber } : skipToken
        ).data as TransformedGetRmaItemsResponse) ||
        ({
            MomCode: '',
            RmaNumber: 0,
            OrderNumber: 0,
            Items: [],
        } as TransformedGetRmaItemsResponse);

    const columns = [
        {
            field: 'IsCompleted',
            headerName: 'Completed?',
            width: 200,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.IsCompleted);
            },
        },
        {
            field: 'Reset',
            headerName: '',
            width: 80,
            renderCell: (params: any) => {
                return (
                    <Button
                        color="error"
                        variant="outlined"
                        sx={{ width: '100%', display: params.row.IsCompletedFromStart ? 'none' : undefined }}
                        data-testid={`reset-button-${params.row.QuantityIndex}`}
                        onClick={event => {
                            event.stopPropagation();
                            const item: TransformedGetRmaItem = params.row;
                            let newSerialVerifies: TransformedGetRmaItemSerialVerify[] = [];
                            for (let i = 0; i < item.SerialVerifies.length; i++) {
                                newSerialVerifies.push({ ...item.SerialVerifies[i], ScanValue: '' });
                            }

                            let isItemFound = false;
                            const newItems = getRmaItemsResponse.Items.map(item => {
                                if (
                                    !isItemFound &&
                                    item.Upc === params.row.Upc &&
                                    item.QuantityIndex === params.row.QuantityIndex
                                ) {
                                    isItemFound = true;
                                    return { ...item, IsCompleted: false, SerialVerifies: newSerialVerifies };
                                } else return item;
                            });
                            dispatch(
                                rmaApi.util.updateQueryData(
                                    'getRmaItems',
                                    { momCode: rmaState.momCode, rmaNumber: rmaState.rmaNumber },
                                    newData => {
                                        newData.Items = newItems;
                                    }
                                )
                            );
                            //reset needs to remove from serialSet
                            dispatch(
                                deleteFromAllSerials(item.SerialVerifies.map(serialVerify => serialVerify.ScanValue))
                            );
                        }}
                    >
                        Reset
                    </Button>
                );
            },
        },
        { field: 'QuantityIndex', headerName: 'Index', width: 100 },
        { field: 'Sku', headerName: 'Sku', width: 300 },
        {
            field: 'SerialVerifies',
            headerName: 'Scanned Serials',
            width: 300,
            valueGetter: (params: any) =>
                params?.row?.SerialVerifies.length === 0
                    ? 'n/a'
                    : params?.row?.SerialVerifies[0]?.ScanValue && params.row.SerialVerifies[0].ScanValue !== ''
                    ? params.row.SerialVerifies.map(
                          (serialVerify: TransformedGetRmaItemSerialVerify) => ' ' + serialVerify.ScanValue
                      )
                    : '',
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        dispatch(setToGetSerialsFor({ upc: params.row.Upc, quantityIndex: params.row.QuantityIndex }));
    };

    return (
        <StyledDataGrid
            columns={columns}
            rows={getRmaItemsResponse.Items || []}
            getRowId={row => row.Upc + row.QuantityIndex}
            columnBuffer={8}
            sx={{
                m: 3,
            }}
            getRowClassName={params => {
                const inProgress =
                    params.row.IsCompleted &&
                    (getRmaItemsResponse.Items as TransformedGetRmaItem[]).filter(
                        item => params.row.Upc === item.Upc && !item.IsCompleted
                    ).length !== 0;

                const completed =
                    params.row.IsCompleted &&
                    (getRmaItemsResponse.Items as TransformedGetRmaItem[]).filter(
                        item => params.row.Upc === item.Upc && !item.IsCompleted
                    ).length === 0;

                //curr is completed, and there exists an item of the same upc that is incomplete
                //curr is completed, and all other items of same upc are complete
                if (inProgress) return 'inProgress';
                else if (completed) return 'accepted';
                else return 'incomplete';
            }}
            onRowClick={handleRowClick}
        />
    );
};

export default RmaDataGrid;
