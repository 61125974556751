import moment from 'moment';

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] || (acc[predicate(value, index, array)] = [])).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const extendNumberToTwoDecimalPlaces = (number: number) => {
    return (Math.round(number * 100) / 100).toFixed(2);
};

export const formatTypedDate = (date: string): string | null => {
    const newDate = moment(date).format('MM/DD/YYYY h:mm.ss a');
    if (newDate !== 'Invalid date') return newDate;
    return null;
};

//takes in generic modal and returns object where everything is optional but at least one property is provided
export type AtLeastOne<T extends Record<string, any>> = keyof T extends infer K
    ? K extends string
        ? Pick<T, K & keyof T> & Partial<T>
        : never
    : never;

//returns string representation of an interface's property
export function propertyOf<TObj>(name: keyof TObj) {
    return name;
}

export const stringToBoolean = (input: string | undefined | null): boolean => {
    if (!input) return false;

    return input.toLowerCase().trim() === 'true';
};
