import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LocationAssignmentRecord } from '../serialLocationModels';
import { formatDate, formatDateWithoutTime } from '../../../common/utils/dateUtil';

const SerialLocationDataGrid = ({ data }: { data: LocationAssignmentRecord[] }) => {
    const columns = [
        {
            field: 'LocationName',
            headerName: 'Location Name',
            width: 150,
        },

        {
            field: 'SerialValue',
            headerName: 'Serial Value',
            width: 150,
        },
        {
            field: 'ShelvedAt',
            headerName: 'Shelved At',
            width: 200,
            valueGetter: (params: any) => {
                const newDate = formatDate(params.row.ShelvedAt);
                if (newDate === 'Invalid date') {
                    return 'N/A';
                }
                return newDate;
            },
        },
        {
            field: 'UnshelvedAt',
            headerName: 'Unshelved At',
            width: 200,
            valueGetter: (params: any) => {
                const newDate = formatDate(params.row.UnshelvedAt);
                if (newDate === 'Invalid date') {
                    return 'N/A';
                }
                return newDate;
            },
        },
    ];

    return (
        <Box>
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={row => row.Id}
                columnBuffer={8}
                sx={{ mt: 2 }}
                density="compact"
                slots={{ toolbar: GridToolbar }}
            />
        </Box>
    );
};

export default SerialLocationDataGrid;
