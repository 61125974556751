import React from 'react';
import { styled } from '@mui/material/styles';
import { Fab } from '@mui/material';

const PREFIX = 'CreatePlusIcon';

const classes = {
    fab: `${PREFIX}-fab`,
};

// const StyledFab = styled(Fab)(({ theme }) => ({
//     [`&.${classes.fab}`]: {},
// }));

export const CreatePlusIcon = ({ showDialog, buttonIcon, label, testId }) => {
    const Icon = buttonIcon;
    return (
        <Fab
            data-testid={testId}
            sx={{ position: 'fixed', bottom: 16, right: 16, outline: 'none !important' }}
            color="primary"
            aria-label={label}
            className={classes.fab}
            onClick={showDialog}
        >
            <Icon />
        </Fab>
    );
};
