import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { useAppDispatch } from '../../../../app/store';
import { CreatePlusIcon } from '../../../../common/components';
import { resetForm, setIsDialogOpen, setMode } from '../../receiversSlice';
import ViewRecievers from '../viewReceivers/ViewRecievers';

const NoAsnTab = () => {
    const dispatch = useAppDispatch();

    return (
        <Box>
            <ViewRecievers shouldNavigateToReceiversPage={true} />

            <CreatePlusIcon
                testId="create-receiver-button"
                label="add"
                buttonIcon={AddIcon}
                showDialog={() => {
                    dispatch(setIsDialogOpen(true));
                    dispatch(setMode('Create'));
                    dispatch(resetForm());
                }}
            />
        </Box>
    );
};

export default NoAsnTab;
