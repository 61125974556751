import { createApi } from '@reduxjs/toolkit/query/react';
import {
    DREAM_URL,
    GET_RECEIVERS_CONFIG_URL,
    GET_RECEIVERS_HISTORY_URL,
    GET_RECEIVERS_INBOUNDS_URL,
    GET_RECEIVERS_STOCKS_URL,
    GET_RECEIVERS_URL,
    PLAY_RECEIVERS_URL,
    POST_RECEIVERS_FORM_URL,
} from '../../app/services/api_constants';
import { dreamBaseQueryWithReauth } from '../../app/services/dreamBaseQueryWithReauth';
import { DreamResponse } from '../tracking/trackingModels';
import {
    GetReceiversRequest,
    History,
    Inbound,
    PONumberConfig,
    PlayReceiverPayload,
    Receiver,
    Stock,
    SubmissionForm,
} from './receiversModels';

export default createApi({
    reducerPath: 'oldReceiversApi',
    baseQuery: dreamBaseQueryWithReauth,
    tagTypes: ['receiversDataGrid'],
    endpoints: builder => ({
        getReceivers: builder.query<Receiver[], GetReceiversRequest>({
            query: param => ({
                url: `${GET_RECEIVERS_URL}?MomCode=${param.momCode}&StartDate=${param.startDate}&EndDate=${param.endDate}&active=${param.active}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            providesTags: ['receiversDataGrid'],
        }),
        getReceiversConfig: builder.query<PONumberConfig, string>({
            query: momCode => ({
                url: `${GET_RECEIVERS_CONFIG_URL}/${momCode}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiverInbounds: builder.query<Inbound[], string>({
            query: momCode => ({
                url: `${GET_RECEIVERS_INBOUNDS_URL}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiverStocks: builder.query<Stock[], string>({
            query: momCode => ({
                url: `${GET_RECEIVERS_STOCKS_URL}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiversHistory: builder.query<History[], { momCode: string; receiverNumber: number }>({
            query: param => ({
                url: `${GET_RECEIVERS_HISTORY_URL}/${param.receiverNumber}`,
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
        }),
        playReceiver: builder.mutation<DreamResponse, Partial<{ momCode: string; body: PlayReceiverPayload }>>({
            query: param => ({
                url: `${PLAY_RECEIVERS_URL}`,
                method: 'PUT',
                body: param.body,
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
        updateReceiver: builder.mutation<DreamResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: `${POST_RECEIVERS_FORM_URL}`,
                method: 'PUT',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
        createReceiver: builder.mutation<DreamResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: `${POST_RECEIVERS_FORM_URL}`,
                method: 'POST',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
    }),
});
