import { Chip, Divider, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { setForm } from '../../workOrderSlice';
import WorkOrderBillingItems from '../Billing/WorkOrderBillingItems';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import WorkOrderBillOfMaterials from '../BillOfMaterials/WorkOrderBillOfMaterials';
import EditHistoryLogs from './EditHistoryLogs';

interface WorkOrderFormRightProps {
    isErrorDisplayed: boolean;
    isDisabled: boolean;
}

const WorkOrderFormRight = ({ isErrorDisplayed, isDisabled }: WorkOrderFormRightProps) => {
    const dispatch = useAppDispatch();
    const form = useAppSelector(state => state.workOrder.form);
    const mode = useAppSelector(state => state.workOrder.workOrderMode);
    const tab = useAppSelector(state => state.workOrder.tab);

    return (
        <Box>
            <Divider sx={{ py: 3 }} variant="middle">
                <Chip label="Services Billing" sx={{ fontSize: 18, padding: 1 }} />
            </Divider>

            <WorkOrderBillingItems isDisabled={isDisabled} />

            <Divider sx={{ py: 3 }} variant="middle">
                <Chip label="Bill of Materials" sx={{ fontSize: 18, padding: 1 }} />
            </Divider>

            <WorkOrderBillOfMaterials isDisabled={isDisabled} />

            <TextField
                variant="outlined"
                label="Bill of Materials Note"
                value={form.BillOfMaterialsComment || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    dispatch(setForm({ ...form, BillOfMaterialsComment: newValue }));
                }}
                sx={{ width: '100%', my: 2 }}
                multiline
                rows={3}
                InputProps={{
                    readOnly: isDisabled,
                }}
            />

            {mode !== 'Create' && tab === 'Work Orders' && <EditHistoryLogs />}
        </Box>
    );
};

export default WorkOrderFormRight;
