import { Paper, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid, GridCellParams, GridColDef, GridTreeNode, GridValueSetterParams } from '@mui/x-data-grid';
import React from 'react';
import { extendNumberToTwoDecimalPlaces, groupBy } from '../../../../common/utils/typedCommonUtils';
import { SHIP_METHOD_COLORS } from '../../constants';
import { MatrixCell } from '../../shipMatrixModel';
import shipMatrixApi from '../../shipMatrixApi';
import { blueGrey } from '@mui/material/colors';

interface ShipMatrixDataGridProps {
    submitData: MatrixCell[];
    selectedCells: MatrixCell[];
    setSelectedCells: React.Dispatch<React.SetStateAction<MatrixCell[]>>;
    propertyToEdit: 'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup';
    setSubmitData: React.Dispatch<React.SetStateAction<MatrixCell[]>>;
}

interface ShipMatrixRow {
    [rowNumber: number]: { WeightOz: string; [columnNumber: number]: MatrixCell };
}

const renderMatrixCell = (
    value: MatrixCell,
    propertyToEdit: 'ShipCode' | 'FreightCost' | 'Markup' | 'FreightCostAndMarkup'
) => {
    let numberToDisplay: number = 0;
    if (propertyToEdit === 'FreightCost') numberToDisplay = value?.FreightCost;
    if (propertyToEdit === 'Markup') numberToDisplay = value?.Markup;
    if (propertyToEdit === 'FreightCostAndMarkup') numberToDisplay = value?.FreightCost + value?.Markup;
    const textToDisplay = propertyToEdit === 'ShipCode' ? '' : extendNumberToTwoDecimalPlaces(numberToDisplay);

    return (
        <Tooltip title={value?.ShipMethod}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Typography textAlign={'center'} variant="body2">
                    {textToDisplay}
                </Typography>
            </Box>
        </Tooltip>
    );
};

const ShipMatrixDataGrid = ({
    submitData,
    selectedCells,
    setSelectedCells,
    propertyToEdit,
    setSubmitData,
}: ShipMatrixDataGridProps) => {
    const [cellToBeUpdated, setCellToBeUpdated] = React.useState({ weightOz: -1, zone: -1 });

    const handleCellUpdate = (params: GridValueSetterParams<ShipMatrixRow, MatrixCell | number>): MatrixCell => {
        const newValue = typeof params.value === 'number' ? params.value : null;
        const cellToUpdate = typeof params.value !== 'number' ? params.value : null;

        if (newValue) {
            //should only update if new value- don't want to overrender
            setSubmitData(oldData => {
                const newData = oldData.map(cell => {
                    return { ...cell } as MatrixCell;
                });

                const matrixToUpdate = newData.find(
                    cell => cell.WeightOz === cellToBeUpdated.weightOz && cell.Zone === cellToBeUpdated.zone
                );

                if (!matrixToUpdate) return oldData;
                if (propertyToEdit === 'FreightCost' && matrixToUpdate?.FreightCost === newValue) return oldData;
                if (propertyToEdit === 'Markup' && matrixToUpdate?.Markup === newValue) return oldData;

                if (propertyToEdit === 'FreightCost') matrixToUpdate.FreightCost = newValue;
                if (propertyToEdit === 'Markup') matrixToUpdate.Markup = newValue;

                return newData;
            });
        }

        if (cellToUpdate) {
            setCellToBeUpdated(oldCell => {
                if (!(oldCell.weightOz === cellToUpdate.WeightOz && oldCell.zone === cellToUpdate.Zone))
                    return { weightOz: cellToUpdate.WeightOz, zone: cellToUpdate.Zone };

                return oldCell;
            });
        }

        //idk this return doesn't do anything since this is controlled component, but will error if no return
        return { ShipMethodId: 2, ShipMethod: 'RAC', WeightOz: 1, Zone: 1, FreightCost: 2, Markup: 0 };
    };

    const columns: GridColDef[] = [
        {
            field: 'WeightOz',
            headerName: 'Oz | Zone ',
            width: 100,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => (
                <Typography sx={{ px: 1 }} variant="body2">
                    {params.value}
                </Typography>
            ),
        },
        {
            field: '1',
            headerName: '1',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '2',
            headerName: '2',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '3',
            headerName: '3',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '4',
            headerName: '4',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '5',
            headerName: '5',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '6',
            headerName: '6',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '7',
            headerName: '7',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '8',
            headerName: '8',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '9',
            headerName: '9',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
        {
            field: '10',
            headerName: '10',
            width: 70,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params: any) => renderMatrixCell(params.value, propertyToEdit),
            type: 'number',
            editable: true,
            valueSetter: handleCellUpdate,
        },
    ];

    const handleGetClassName = (params: GridCellParams<any, any, any, GridTreeNode>) => {
        const weightOz = params.id;
        const zone = params.field;
        const isCellSelected = selectedCells.find(cell => {
            return cell.Zone === Number(zone) && cell.WeightOz === Number(weightOz);
        });

        if (isCellSelected) return 'Selected';
        const shipMethod = params.row[zone]?.ShipMethod ? String(params.row[zone]?.ShipMethod) : '';
        return shipMethod;
    };

    const handleOnCellClick = (params: GridCellParams<any, unknown, unknown, GridTreeNode>) => {
        const weightOz = params.id;
        const zone = params.field;
        const newlySelectedCell = submitData.find(cell => {
            return cell.Zone === Number(zone) && cell.WeightOz === Number(weightOz);
        });

        if (!newlySelectedCell) return;

        const shouldAddSelectedRange = selectedCells.length === 1;

        if (shouldAddSelectedRange) {
            const topLeftCell = selectedCells[0];
            const cellsToAdd = submitData.filter(
                cell =>
                    cell.WeightOz >= topLeftCell.WeightOz &&
                    cell.WeightOz <= newlySelectedCell.WeightOz &&
                    cell.Zone >= topLeftCell.Zone &&
                    cell.Zone <= newlySelectedCell.Zone
            );
            setSelectedCells(cellsToAdd);
        } else {
            setSelectedCells([newlySelectedCell]);
        }
    };
    const getShipmethodsResponse = shipMatrixApi.endpoints.getShipMethods.useQuery();

    const getTransformedData = () => {
        const groupedByWeight = groupBy(submitData, (cell: MatrixCell) => cell.WeightOz.toString());
        let result: ShipMatrixRow[] = [];

        Object.keys(groupedByWeight).forEach(function (key, index) {
            let currRow: any = { WeightOz: key };

            groupedByWeight[key].forEach((cell: MatrixCell) => {
                currRow = {
                    ...currRow,
                    [cell.Zone]: cell,
                };
            });

            result.push(currRow);
        });
        return result;
    };

    const backgroundColors = () => {
        const result: any = { '& .Selected': { backgroundColor: blueGrey['A100'] } };

        getShipmethodsResponse?.data?.Data.forEach((shipMethod, index) => {
            result[`& .${shipMethod.Name}`] = { backgroundColor: SHIP_METHOD_COLORS[index] };
        });

        return result;
    };

    return (
        <Paper elevation={3} sx={{ p: 1, mt: 2, width: '80%' }}>
            <DataGrid
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                hideFooter
                rows={getTransformedData()}
                columns={columns}
                getRowId={row => row.WeightOz}
                columnBuffer={11}
                density="compact"
                rowHeight={25}
                showColumnVerticalBorder={true}
                showCellVerticalBorder={true}
                getCellClassName={handleGetClassName}
                onCellClick={handleOnCellClick}
                sx={{
                    userSelect: 'none',
                    '& .MuiDataGrid-cell': {
                        padding: 0,
                    },
                    ...backgroundColors(),
                }}
            />
        </Paper>
    );
};

export default ShipMatrixDataGrid;
