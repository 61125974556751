import { Button } from '@mui/material';
import { DataGrid, GridEventListener } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getReceiverPdfUrl } from '../../../../app/services/urlFunctions';
import { useAppDispatch } from '../../../../app/store';
import { renderBooleanCell } from '../../../../common/components/icon/BooleanCell';
import { formatDateWithoutTime } from '../../../../common/utils/dateUtil';
import { SubmissionForm } from '../../receiversModels';
import { setMode } from '../../receiversSlice';
import PlayReceiverConfirmDialog from './PlayReceiverConfirmDialog';

interface ReceiversDataGridProps {
    shouldNavigateToReceiversPage: boolean;
    receivers: SubmissionForm[];
}

const ReceiversDataGrid: React.FC<ReceiversDataGridProps> = ({
    shouldNavigateToReceiversPage,
    receivers,
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [receiverToPlay, setReceiverToPlay] = React.useState<{ client: string; number: number } | undefined>(
        undefined
    );

    const columns = [
        {
            field: 'Number',
            headerName: 'Receiver No.',
            flex: 1,
            headerClassName: 'wrapping--header',
        },
        {
            field: 'Client',
            headerName: 'MOM',
            flex: 1,
        },
        {
            field: 'RecDate',
            headerName: 'Received Date',
            flex: 2,
            valueGetter: (params: any) => formatDateWithoutTime(params.row.RecDate),
        },
        {
            field: 'RecBy',
            headerName: 'Received By',
            flex: 2,
        },

        {
            field: 'Po',
            headerName: 'PO',
            flex: 2,
        },
        {
            field: 'Project',
            headerName: 'Project Type',
            flex: 3,
        },
        {
            field: 'Played',
            headerName: 'Played?',
            flex: 1,
            renderCell: (params: any) => {
                return renderBooleanCell(params.row.Played);
            },
        },
        {
            field: 'Play',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Button
                        variant="outlined"
                        disabled={params.row.Played}
                        sx={{ width: '100%' }}
                        onClick={event => {
                            event.stopPropagation();
                            const clickedReceiver: SubmissionForm = params.row;
                            if (clickedReceiver.Number)
                                setReceiverToPlay({ client: clickedReceiver.Client, number: clickedReceiver.Number });
                        }}
                    >
                        Play
                    </Button>
                );
            },
        },
        {
            field: 'Print',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <Button
                        variant="outlined"
                        target="_blank"
                        href={getReceiverPdfUrl(params.row.Client, params.row.Number)}
                        onClick={event => {
                            event.stopPropagation();
                        }}
                    >
                        Print
                    </Button>
                );
            },
        },
    ];

    const handleRowClick: GridEventListener<'rowClick'> = params => {
        const newForm = params.row as SubmissionForm;
        // dispatch(setForm(newForm));
        // dispatch(setIsDialogOpen(true));
        dispatch(setMode('View'));
        const newRoute = shouldNavigateToReceiversPage
            ? `/receivers/${newForm?.Number}`
            : `/inbound/resume/${newForm?.Number}`;

        navigate(newRoute);
    };

    return (
        <React.Fragment>
            <DataGrid
                disableRowSelectionOnClick
                rows={receivers || []}
                columns={columns}
                columnBuffer={14}
                getRowId={row => row.Number || -1}
                sx={{
                    m: 1,
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontSize: 10,
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        textAlign: 'center',
                        textOverflow: 'clip',
                    },
                    '&:hover': { cursor: 'pointer' },
                    '& .MuiDataGrid-footerContainer:hover': { cursor: 'auto' },
                }}
                initialState={{
                    // ...receivers.initialState,
                    sorting: {
                        sortModel: [{ field: 'Number', sort: 'desc' }],
                    },
                }}
                onRowClick={handleRowClick}
            />

            {receiverToPlay && (
                <PlayReceiverConfirmDialog
                    receiverToPlay={receiverToPlay}
                    handleCancel={() => {
                        setReceiverToPlay(undefined);
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default ReceiversDataGrid;
