import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialWorkOrderForm } from './components/WorkOrderElement';
import { cleanTemplateData } from './util';
import { WorkOrderData } from './workOrderModels';

type WorkOrderModeType = 'Edit' | 'Create' | 'View';
type TabType = 'Work Orders' | 'Templates' | 'End Of Month';

interface WorkOrderState {
    form: WorkOrderData;
    workOrderMode: WorkOrderModeType;
    tab: TabType;
}

const initialState: WorkOrderState = {
    form: initialWorkOrderForm,
    workOrderMode: 'View',
    tab: 'Work Orders',
};

const slice = createSlice({
    name: 'workOrder',
    initialState,
    reducers: {
        setForm: (state, action: PayloadAction<WorkOrderData>) => {
            state.form = action.payload;
        },
        overrideForm: (state, action: PayloadAction<any>) => {
            state.form = { ...state.form, ...action.payload };
        },
        setMode: (state, action: PayloadAction<WorkOrderModeType>) => {
            state.workOrderMode = action.payload;
        },
        setTab: (state, action: PayloadAction<TabType>) => {
            state.tab = action.payload;
        },
        cleanTemplateIntoWorkOrderData: state => {
            state.form = cleanTemplateData(state.form);
        },
    },
});

export const { setForm, overrideForm, setMode, setTab, cleanTemplateIntoWorkOrderData } = slice.actions;

export default slice.reducer;
