import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { green, red } from '@mui/material/colors';
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridRenderCellParams,
    GridTreeNodeWithRender,
    GridValidRowModel,
} from '@mui/x-data-grid';
import React, { memo } from 'react';
import { useAppSelector } from '../../../../../app/store';
import { renderBooleanCell } from '../../../../../common/components/icon/BooleanCell';
import { SubmissionFormItem } from '../../../receiversModels';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .clickable': {
        '&:hover': { cursor: 'pointer' },
    },

    '& .incomplete': {
        backgroundColor: red['A100'],
        '&:hover': { backgroundColor: red['A200'] },
    },

    '& .complete': {
        backgroundColor: green['A200'],
        '&:hover': { backgroundColor: green['A400'] },
    },
}));

const ItemsDataGrid = memo(
    ({
        items,
        handleItemClick,
        handleItemDelete,
        hasAsn,
    }: {
        items: SubmissionFormItem[];
        handleItemClick: (item: SubmissionFormItem) => void;
        handleItemDelete: (item: SubmissionFormItem) => void;
        hasAsn: boolean;
    }) => {
        const mode = useAppSelector(state => state.receivers.mode);

        const handleRowDelete = (
            params: GridRenderCellParams<GridValidRowModel, any, any, GridTreeNodeWithRender>,
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
            event.stopPropagation();
            const row = params.row as SubmissionFormItem;

            handleItemDelete(row);
        };

        const columns: readonly GridColDef<GridValidRowModel>[] = [
            {
                field: 'Item',
                headerName: 'SKU',
                flex: 2,
            },
            {
                field: 'Descript',
                headerName: 'Description',
                flex: 3,
            },
            {
                field: 'LineId',
                headerName: 'Line ID',
                flex: 1,
            },
            {
                field: 'RecCrtncount',
                headerName: '# per Ctn',
                flex: 1,
            },
            {
                field: 'RecCaseqty',
                headerName: '# Ctns',
                flex: 1,
            },
            {
                field: 'RecPartial',
                headerName: 'Loose Qty',
                flex: 1,
            },
            {
                field: 'RecQty',
                headerName: 'Total',
                flex: 1,
            },
            {
                field: 'PlQty',
                headerName: "Expt'd",
                flex: 1,
            },
            {
                field: 'Difference',
                headerName: 'Diff.',
                flex: 1,
                valueGetter: params => params.row.RecQty - params.row.PlQty,
            },
            {
                field: 'Transid',
                headerName: 'Played',
                flex: 1,
                renderCell: (params: any) => {
                    return renderBooleanCell(!!params?.row?.Transid);
                },
            },
            {
                field: 'Notes',
                headerName: 'Notes',
                flex: 2,
            },
            {
                field: 'Delete',
                headerName: mode === 'View' ? '' : 'Delete',
                flex: 1,
                renderCell: params => {
                    return mode === 'View' ? (
                        <></>
                    ) : (
                        <IconButton edge="end" onClick={event => handleRowDelete(params, event)}>
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        ];

        const handleRowClick: GridEventListener<'rowClick'> = params => {
            const item = params.row as SubmissionFormItem;
            handleItemClick(item);
        };

        return (
            <StyledDataGrid
                rows={items}
                columns={columns}
                columnBuffer={10}
                getRowId={row => row.Id || row.Item}
                density="compact"
                columnVisibilityModel={{
                    Transid: mode !== 'Create',
                    Descript: !hasAsn,
                    LineId: hasAsn,
                    PlQty: hasAsn,
                    Difference: hasAsn,
                }}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                getRowClassName={params => {
                    if (!params.row.LineId) return 'clickable';
                    if (params.row.RecQty === 0) return 'incomplete';
                    if (params.row.RecQty > 0) return 'complete';
                    return '';
                }}
            />
        );
    }
);

export default ItemsDataGrid;
