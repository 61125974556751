import { Breadcrumbs, Link, LinkProps, Link as RouterLink, Typography } from '@mui/material';
import { Box, Breakpoint, Container } from '@mui/system';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import {
    setIsNavigationWarningDialogOpen,
    setNavigationWarningDialogDestination,
} from '../../../features/notification/notificationSlice';

interface PageProps {
    title: string;
    childrenElement: JSX.Element;
    maxWidth?: Breakpoint;
    breadcrumbNameMap?: { [key: string]: string };
}

interface LinkRouterProps extends LinkProps {
    replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}

const Page = ({ title, childrenElement, maxWidth = 'lg', breadcrumbNameMap }: PageProps): JSX.Element => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shouldShowNavigationWarningDialog = useAppSelector(
        state => state.notification.shouldShowNavigationWarningDialog
    );

    return (
        <Container sx={{ pt: '1rem', pb: '1rem' }} maxWidth={maxWidth}>
            <Box>
                <Typography variant="h5" color="initial" sx={{ fontWeight: '500', margin: '1rem 0' }}>
                    {title}
                </Typography>
                {breadcrumbNameMap && (
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography
                            onClick={() => {
                                if (shouldShowNavigationWarningDialog) dispatch(setIsNavigationWarningDialogOpen(true));
                                else navigate('/');
                            }}
                            key={'/'}
                            sx={{ '&:hover': { textDecoration: 'underline' } }}
                        >
                            Home
                        </Typography>
                        {pathnames.map((value, index) => {
                            const last = index === pathnames.length - 1;
                            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                            return last || !breadcrumbNameMap[to] ? (
                                <Typography color="text.primary" key={to}>
                                    {breadcrumbNameMap[to] || value}
                                </Typography>
                            ) : (
                                <Typography
                                    onClick={() => {
                                        if (shouldShowNavigationWarningDialog) {
                                            dispatch(setIsNavigationWarningDialogOpen(true));
                                            dispatch(setNavigationWarningDialogDestination(to));
                                        } else navigate(to);
                                    }}
                                    key={to}
                                    sx={{ '&:hover': { textDecoration: 'underline' } }}
                                >
                                    {breadcrumbNameMap[to]}
                                </Typography>
                            );
                        })}
                    </Breadcrumbs>
                )}
            </Box>

            {childrenElement}
        </Container>
    );
};

export default Page;
