import React, { useState, useEffect } from 'react';
import {
    Table,
    TableContainer,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    TablePagination,
    Typography,
    Button,
} from '@mui/material';
import { Spinner } from '../../../common/components/Spinner/Spinner';
import { getComparator, stableSort } from '../../../common/utils/commonUtils';
import { useDispatch } from 'react-redux';
import { editUserRoleAction, resetRoleSuccessStatusAction } from '../../../archive/store/actions/action';
import EnhancedTableToolbar from './EnhancedTableToolbar2';
import EnhancedTableHead from './EnhancedTableHead2';

const RoleTable = ({
    classes,
    userDataList,
    activeTab,
    isLoading,
    selectedRole,
    isAssigningUsers,
    setIsAssigningUsers,
}) => {
    const [orderBy, setOrderBy] = useState('Name');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [unAssignedUsers, setUnAssignedUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAssigningUsers) {
            setUnAssignedUsers([...userDataList]);
        } else {
            setAssignedUsers([...userDataList]);
        }
        setUserList([...userDataList]);
    }, [activeTab, userDataList, isAssigningUsers]);

    useEffect(() => {
        if (searchText !== '' && searchText.trim('') !== '') {
            setUserList(userDataList.filter(usr => usr?.userName?.toLowerCase().includes(searchText.toLowerCase())));
        } else {
            setUserList(userDataList);
        }
    }, [searchText, userDataList]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectAllClick = event => {
        const newSelecteds = [...userDataList];
        if (event.target.checked) {
            if (isAssigningUsers) {
                setAssignedUsers(newSelecteds.map(usr => ({ ...usr, assigned: true })));
                setUnAssignedUsers([]);
                setUserList(userList.map(usr => ({ ...usr, assigned: true })));
            } else {
                setAssignedUsers([]);
                setUnAssignedUsers(newSelecteds.map(usr => ({ ...usr, assigned: false })));
                setUserList(userList.map(usr => ({ ...usr, assigned: false })));
            }
            return;
        }
        if (isAssigningUsers) {
            setAssignedUsers([]);
            setUnAssignedUsers(newSelecteds.map(usr => ({ ...usr, assigned: false })));
            setUserList(userList.map(usr => ({ ...usr, assigned: false })));
        } else {
            setUnAssignedUsers([]);
            setAssignedUsers(newSelecteds.map(usr => ({ ...usr, assigned: true })));
            setUserList(userList.map(usr => ({ ...usr, assigned: true })));
        }
    };

    const handleClick = (event, user) => {
        const selectedIndex = userList.findIndex(usr => usr.userId === user.userId);
        const copyData = [...userList];
        copyData[selectedIndex].assigned = !copyData[selectedIndex].assigned;
        setUserList(copyData);
        if (isAssigningUsers) {
            if (user.assigned) {
                setUnAssignedUsers(unAssignedUsers.concat({ ...user, assigned: !user.assigned }));
                setAssignedUsers(assignedUsers.filter(usr => usr.userId !== user.userId));
            } else {
                setAssignedUsers(assignedUsers.concat({ ...user, assigned: !user.assigned }));
                setUnAssignedUsers(unAssignedUsers.filter(usr => usr.userId !== user.userId));
            }
        } else {
            if (user.assigned) {
                setAssignedUsers(assignedUsers.filter(usr => usr.userId !== user.userId));
                setUnAssignedUsers(unAssignedUsers.concat({ ...user, assigned: !user.assigned }));
            } else {
                setUnAssignedUsers(unAssignedUsers.filter(usr => usr.userId !== user.userId));
                setAssignedUsers(assignedUsers.concat({ ...user, assigned: !user.assigned }));
            }
        }
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, userList.length - page * rowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSubmit = () => {
        let users = [];

        if (isAssigningUsers) {
            users = assignedUsers;
        } else {
            users = unAssignedUsers;
        }

        if (users?.length > 0) {
            dispatch(resetRoleSuccessStatusAction());
            dispatch(
                editUserRoleAction({
                    data: {
                        roleId: selectedRole.id,
                        users,
                    },
                    actionType: !isAssigningUsers ? 'UNASSIGNED' : 'ASSIGNED',
                })
            );
        }
    };

    const isSelected = userId => {
        if (isAssigningUsers) {
            if (assignedUsers.find(usr => usr.userId === userId)) {
                return true;
            }
            return false;
        } else {
            if (unAssignedUsers.find(usr => usr.userId === userId)) {
                return true;
            }
            return false;
        }
    };

    return (
        <div>
            <EnhancedTableToolbar
                numSelected={unAssignedUsers.length}
                selectedRole={selectedRole}
                handleSubmit={handleSubmit}
                searchText={searchText}
                setSearchText={setSearchText}
                activeTab={activeTab}
                setIsAssigningUsers={setIsAssigningUsers}
            />
            <TableContainer>
                <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={!isAssigningUsers ? unAssignedUsers.length : assignedUsers.length}
                        rowCount={userList.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 300,
                                            width: 'calc(100vw - 60px)',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Spinner />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(userList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.userId);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={event => handleClick(event, { ...row })}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.userId}
                                            selected={isItemSelected}
                                            color="primary"
                                            classes={{
                                                selected: classes.selected,
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    color="primary"
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                style={{
                                                    fontWeight: 500,
                                                    cursor: 'pointer',
                                                    textDecoration:
                                                        isItemSelected && !isAssigningUsers ? 'line-through' : 'none',
                                                }}
                                            >
                                                {row.userName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 43 * emptyRows }}>
                                <TableCell colSpan={2} align="center">
                                    <Typography variant="body1" color="initial">
                                        {userDataList.length === 0
                                            ? activeTab
                                                ? activeTab === 0
                                                    ? ' No user has been assigned to this role'
                                                    : 'No more users available to assign role'
                                                : 'No user has been assigned to this role'
                                            : null}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {
                // !isAssigningUsers ? unAssignedUsers.length > 0 && (
                //   <Tooltip title="Un-Assign Users" aria-label="add">
                //   <Fab aria-label={''} className={classes.unAssignFab} onClick={handleSubmit}>
                //       <DeleteIcon />
                //   </Fab>
                //   </Tooltip>
                // )
                // :
                isAssigningUsers
                    ? assignedUsers.length > 0 && (
                          <Button
                              variant="contained"
                              color="primary"
                              data-testid="assign-user-btn"
                              className={classes.assignFab}
                              style={{ bottom: '48px' }}
                              onClick={handleSubmit}
                          >
                              Assign Users
                          </Button>
                          //     <Tooltip title="Assign Users" aria-label="add">
                          //     <Fab color='primary' aria-label={''} className={classes.assignFab} style={{bottom:'48px'}} onClick={handleSubmit}>
                          //     <AddIcon />
                          // </Fab>
                          // </Tooltip>
                      )
                    : null
            }
        </div>
    );
};

export default RoleTable;
